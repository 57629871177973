<template>
  <!--begin::Agreement Listing-->
  <div class="agreement-template">
    <PageHeaderCount
      moduleType="agreement"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="all_agreements"
      countkey="agreement_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'agreement-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template>
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_agreements
                          }}</template>
                          <template v-else>{{
                            item.agreement_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <v-btn
                  v-if="false"
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button"
                  v-on:click="packageDialog = true"
                  color="cyan white--text"
                >
                  <v-icon dark left>mdi-plus</v-icon> Create
                </v-btn>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'agreement'
                        }
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <template v-if="false">
                <v-menu content-class="custom-menu-list" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <!-- <PageTips module="agreement"></PageTips> -->
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Agreement"
          :basicSearchFields="['barcode', 'reference', 'subject']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: static"
        >
          <ListingTable
            :columnCount.sync="defaultColShow.length"
            :dataLoading.sync="dataLoading"
            :rowData.sync="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!dataLoading">
                  <template v-if="!lodash.isEmpty(rowData)">
                    <tr v-for="(data, index) in rowData" :key="index" link>
                      <template v-for="cols in defaultColDefs">
                        <td
                          v-if="cols.visible"
                          :key="cols.id"
                          :class="{
                            'simple-table-td': !cols.checkbox,
                            'checkbox-width-limit': cols.checkbox
                          }"
                          :style="{ 'max-width': cols.width }"
                          v-on:click="detailDialog(data)"
                        >
                          <template v-if="cols.field == 'barcode'">
                            <Barcode :barcode="data.barcode"></Barcode>
                          </template>
                          <template v-else-if="cols.field == 'quotation'">
                            <Barcode :barcode="data.package.barcode" route="package.sales.detail" :id.sync="data.package.id"></Barcode>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Title: </b>{{ data.package.quotation_title }}
                            </p>
                          </template>
                          <template v-else-if="cols.field == 'basic_detail'">
                            <div class="agreement-listing">
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Subject: </b>{{ data.subject }}
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Reference #: </b>
                                <template v-if="data.reference">
                                  {{ data.reference }}
                                </template>
                                <template v-else>
                                  <em class="text--secondary"
                                    >No Reference #</em
                                  >
                                </template>
                              </p>
                           <!--   <p class="m-0 custom-nowrap-ellipsis">
                                <b>Agreement Type: </b>{{ data.agreement_type }}
                              </p>-->
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Agreement Value: </b
                                >{{
                                  $accountingJS.formatMoney(data.agreement_value)
                                }}
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'status'">
                            <CustomChip color="cyan" text-color="white" small :text="data.status_text" tooltip-text="Status"></CustomChip>
                            <template v-if="data.agreement || data.resident">
                              <CustomChip :color="getCSTColor(data.customer_status)" text-color="white" small :text="data.customer_status_text" tooltip-text="Customer Status"></CustomChip>
                              <CustomChip :color="getCondiColor(data.mail_status)" text-color="white" small :text="data.mail_status_text" tooltip-text="Is Mail Sent ?"></CustomChip>
                            </template>
                          </template>
                          <template v-else-if="cols.field == 'dates'">
                            <div class="agreement-listing">
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Start Date: </b>
                                <template v-if="data.start_date">{{
                                  formatDate(data.start_date)
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Start Date</em
                                  ></template
                                >
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>End Date: </b>
                                <template v-if="data.end_date">{{
                                  formatDate(data.end_date)
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary"
                                    >No End Date</em
                                  ></template
                                >
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'download'">
                            <div v-on:click.stop.prevent="downloadFile(data)" class="custom-mime-content agreement-download-icon">
                            <span class="svg-icon svg-icon-lg custom-mime-icon mr-4">
                              <!--begin::Svg Icon-->
                              <inline-svg :src="$assetURL('media/mime/pdf.svg')"/>
                              <!--end::Svg Icon-->
                            </span>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'project'">
                            <template v-if="data.project">
                              <div v-on:click.stop>
                                <Barcode
                                  :barcode="data.project.barcode"
                                  route="project.detail"
                                  :id.sync="data.project.id"
                                ></Barcode>
                              </div>
                            </template>
                          </template>
                          <template v-else-if="cols.field == 'customer'">
                            <div
                              class="agreement-listing-customer"
                              v-if="data.customer"
                            >                              
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.customer.default_person"
                              >
                                <b>Name: </b>
                                {{ data.customer.default_person.display_name }}
                              </p>
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.customer"
                              >
                                <b>Company: </b>
                                {{ data.customer.company_name }}
                              </p>
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.customer.default_person"
                              >
                                <b>Email: </b>
                                {{ data.customer.default_person.primary_email }}
                              </p>
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.customer.default_person"
                              >
                                <b>Phone: </b>
                                {{ data.customer.default_person.primary_phone }}
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'added_at'">
                            <TableActivity
                              v-if="!lodash.isEmpty(data.added_by)"
                              :data.sync="data"
                            >
                              <template v-slot:display_name>
                                {{ data.added_by.display_name }}
                              </template>
                              <template v-slot:date_time>
                                {{ data.created_at }}
                              </template>
                              <template v-slot:format_date_time>
                                {{ formatDateTime(data.added_at) }}
                              </template>
                            </TableActivity>
                          </template>
                          <template v-else-if="cols.field == 'updated_at'">
                            <TableActivity
                              v-if="!lodash.isEmpty(data.updated_by)"
                              :data.sync="data"
                            >
                              <template v-slot:display_name>
                                {{ data.updated_by.display_name }}
                              </template>
                              <template v-slot:date_time>
                                {{ data.modified_at }}
                              </template>
                              <template v-slot:format_date_time>
                                {{ formatDateTime(data.updated_at) }}
                              </template>
                            </TableActivity>
                          </template>
                          <template v-else>
                            <div v-html="printRowData(cols, data)"></div>
                          </template>
                        </td>
                      </template>
                    </tr>
                  </template>
                  <tr v-else-if="!dataLoading">
                    <td :colspan="defaultColDefs.length" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no agreement at the moment.
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :common-dialog="searchDialog">
          <template v-slot:title>Filter Case Trust</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="position: static"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <DateRangePicker
                      v-model="dates"
                      prop_label="Dates Range"
                    ></DateRangePicker>
                  </v-col>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Agreement')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <Dialog :common-dialog="categoryDialog" :dialog-width="dialogWidth">
          <template v-slot:body>
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="4" class="custom-border-right">
                    <h3 class="font-weight-600 color-custom-blue text-uppercase text-center">Accepted Quotations</h3>
                    <template v-if="customerQuotations.length">
                    <v-list v-for="(quote, index) in customerQuotations" :key="index" two-line>
                      <v-list-item link v-on:click="selectQuotation(quote)" class="agreement-category-list">
                        <v-list-item-content>
                          <v-list-item-title class="font-size-18 font-weight-600 pb-2">{{quote.barcode}} {{quote.quotation_title}}</v-list-item-title>
                          <v-list-item-subtitle class="font-size-16">{{formatMoney(quote.total)}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    </template>
                    <template v-else>
                      <p
                        class="my-8 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          width="30px"
                          :src="$assetURL('media/error/empty.png')"
                          class="mr-2 row-not-found-image"
                        />
                        Uhh... There are no accepted quotation at the moment.
                      </p>
                    </template>
                  </v-col>
                  <v-col md="4" class="custom-border-right">
                    <h3 class="font-weight-600 color-custom-blue text-uppercase text-center">Agreement Forms</h3>   
                    <template v-if="agreementForms.length">
                      <v-list v-for="(form, index) in agreementForms" :key="index">
                        <v-list-item link v-on:click="selectAgreementForm(form)" class="agreement-category-list">
                          <v-list-item-content>
                            <v-list-item-title class="font-size-18">{{form.title}}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </template>
                    <template v-else>
                      <p
                        class="my-8 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          width="30px"
                          :src="$assetURL('media/error/empty.png')"
                          class="mr-2 row-not-found-image"
                        />
                        Uhh... There are no agreement forms at the moment.
                      </p>
                    </template>
                  </v-col>
                  <v-col md="4" class="my-auto" v-on:click="routeToIndividual()">
                    <h3 class="link font-weight-600 px-2 py-6 agreement-category-list color-custom-blue text-uppercase text-center">Standalone Agreement</h3>
                  </v-col>
                </v-row>
              </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="categoryDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <case-trust-dialog
          case-trust-detail
          :case-trust-dialog="case_trust_dialog"
          v-model="case_trust"
          v-on:close="case_trust_dialog = false"
        ></case-trust-dialog>
        <template v-if="customerDialog">
          <CustomerDialog
            :customerDialog.sync="customerDialog"
            v-on:closeDialog="customerDialog = false"
            v-on:resetAll="customerDialog = false"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Agreement Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
//import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import DateRangePicker from "@/view/pages/partials/DateRangePicker.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomChip from "@/view/pages/partials/CustomChip.vue";
import { GET } from "@/core/services/store/request.module";
import CaseTrustDialog from "@/view/pages/quotation/Case-Trust-Dialog.vue";
import { SearchEventBus } from "@/core/lib/search.lib";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "agreement-list",
  data() {
    return {
      customerDialog: false,
      exportLoading: false,
      categoryDialog: false,
      case_trust_dialog: false,
      customerId: null,
      dates: [],
      case_trust: {},
      customerQuotations: [],
      agreementForms: [],
      pageModule: "agreement-listing",
      routeAPI: "agreements",
      routeName: "agreement",
      routeDetailName: "agreement.detail",
      status: "all",
      statusList: [],
      moreActions: [
        /*{
          title: "Export Agreement(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export"
        },
        {
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh"
        }
      ],
      categoryLoading: false
    };
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = undefined;
        }
      }
    }
  },
  components: {
    CaseTrustDialog,
    CustomChip,
    DateRangePicker,
    CustomerDialog,
    PageHeaderCount,
    draggable,
    CustomStatus,
    //PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog
  },
  methods: {
    downloadFile(row) {
      if(row.pdf_url){
        window.open(row.pdf_url, "_blank");
      }
    },
    detailDialog(row) {      
      this.case_trust.agreement_date = row.agreement_date;

      this.case_trust.first_party = {
        name: row.first_party_name,
        contact_no: row.first_party_contact_no,
        nric_no: row.first_party_nric_no
      }

      this.case_trust.second_party = {
        name: row.second_party_name,
        contact_no: row.second_party_contact_no,
        nric_no: row.second_party_nric_no
      }

      this.case_trust.attachment = row.attachment;
      this.case_trust.address = row.address;
      this.case_trust.estimated_date = row.estimated_date;
      this.case_trust.package_sum = row.package_sum;
      this.case_trust.additional_work_sum = row.additional_work_sum;
      this.case_trust.standalone_contract_sum = row.standalone_contract_sum;
      this.case_trust.package = row.case_trust_packages;
      this.case_trust.contract = row.case_trust_standalone;
      
      this.case_trust_dialog = true;
    },
    getCSTColor(status) {
      let result = null;
      switch(status) {
        case 0:
          result = "orange lighten-1";
        break;
        case 1:
          result = "green lighten-1";
        break;
        case 2:
          result = "red lighten-1";
        break;
      }
      return result;
    },
    getCondiColor(status) {
      let result = null;
      switch(status) {
        case false:
          result = "orange lighten-1";
        break;
        case true:
          result = "green lighten-1";
        break;
      }
      return result;
    },
    routeToIndividual() {
      this.$router.push(
        this.getDefaultRoute("agreement.create", {
          query: { customer: this.customerId }
        })
      );
    },
    selectQuotation({id}) {
      this.$router.push(
        this.getDefaultRoute("agreement.create", {
          query: { customer: this.customerId, quotation: id, agreement: 1 }
        })
      );
    },
    selectAgreementForm(row) {
      if(row.id == 1){
        this.$router.push(
          this.getDefaultRoute("agreement.form", {
            query: { customer: this.customerId, agreement: 1 }
          })
        );
      }else if(row.id == 2) {
        this.$router.push(
          this.getDefaultRoute("agreement.residentialform", {
            query: { customer: this.customerId, agreement: 2 }
          })
        );
      }
    },
    selectCustomer(customer) {
      this.customerId = customer;      
      this.categoryLoading = true;
      this.$store
        .dispatch(GET, { url: `agreements/options/${customer}` })
        .then(({ data }) => {
          this.customerQuotations = data.quotations;
          this.agreementForms = data.agreement_forms;
          this.categoryDialog = true;
          this.customerDialog = false;
        })
        .finally(() => {
          this.categoryLoading = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.AgreementExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("agreement");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Case Trust"
      }
    ]);

    SearchEventBus.$emit("search-module-title", "Case Trust");
  },
  beforeMount() {
    const _this = this;

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "Package",
        field: "quotation",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 1,
        width: "300px"
      },
      {
        headerName: "Customer",
        field: "customer",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "300px"
      },
      {
        headerName: "Status",
        field: "status",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "200px"
      },
      {
        headerName: "Download",
        field: "download",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 4,
        width: "200px"
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 5,
        width: "170px"
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: true,
        order: 7,
        width: "170px"
      }
    ];

    const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, function(row) { return row.visible; });

    _this.defaultColShow = defaultColDefs.map(col => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    }
  }
};
</script>
